function postForm(targetURL, objForm) {
    var data = new FormData(objForm);
    var xhr = new XMLHttpRequest();

    xhr.open("POST", targetURL, true);
    xhr.onload = function() {

    };
    xhr.send(data);
}

function setUI() {
    var ui = document.getElementById("formui");
    var x = document.getElementById("formval");

    if ((ui) && (x)) { ui.value = x.value; }
}

function submitForm(id) {
    var f = document.getElementById(id);

    f.submit();
}

function toggleDisabled(element, disabled) {
    if (disabled) {
        element.classList.add("disabled");
    } else {
        element.classList.remove("disabled");
    }
}

function closeDialog(name) {
    $("#" + name + "Title").empty();
    $("#" + name + "Content").empty();
    $("#" + name).css("display", "none");
}

function openDialog(name, title, endpoint, callback = null) {
    $("#" + name).css("display", "flex");
    $("#" + name + "Title").empty().html(title);

    if ((typeof(endpoint) !== typeof(undefined)) && (endpoint !== "")) {
        $.ajax({
            url: endpoint,
            type: 'POST',
            success: function(data) {
                $("#" + name + "Content").empty().html(data);

                if (typeof(callback) === "function") {
                    callback();
                }

                $(".first").focus();
            },
            error: function(request, error) {
                $("#" + name + "Content").empty().html("Error loading dialog content.");
            }
        });
    }
}

function messageBox(name, title, message) {
    $("#" + name).css("display", "flex");
    $("#" + name + "Title").empty().html(title);
    $("#" + name + "Message").empty().html(message);
}

function closeMessage(name) {
    $("#" + name + "Title").empty();
    $("#" + name + "Message").empty();
    $("#" + name).css("display", "none");
}